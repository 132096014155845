@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    margin: 0;
}

::-webkit-scrollbar {
    width: 2px;
    height: 2px;
}

::-webkit-scrollbar-track {
    background: #0B3C52;
}

::-webkit-scrollbar-thumb {
    background-color: #85de004a;
    border: none;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
}

/* Table */
table thead th:first-child {
    border-top-left-radius: 0.5rem;
}

table thead th:last-child {
    border-top-right-radius: 0.5rem;
}

table tbody tr:last-child td:first-child {
    border-bottom-left-radius: 0.5rem;
}

table tbody tr:last-child td:last-child {
    border-bottom-right-radius: 0.5rem;
}

table tbody tr:last-child td:last-child {
    border-bottom-right-radius: 10px;
}

.graph-component svg path:not(.graph_mixed svg path) {
    stroke-width: 0;
}

.graph-component text {
    fill: white;
}